<template>
  <div>
    <div>
      <b-row>
        <b-col>
          <b-card>
            <b-row class="ml-1"> 
              <div v-if="!showCreate">
                <b-button variant="relief-success" class="mr-2" @click="showCreate = true">
                  <feather-icon icon="FileTextIcon" />
                  Visualizar relatório
                </b-button>
              </div>
              <div v-else>
                <b-button variant="relief-danger" class="mr-2" @click="showCreate = false">
                  <feather-icon icon="ChevronLeftIcon" />
                  Voltar
                </b-button>
              </div>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-show="!showCreate">
      <b-overlay :show="isLoadingLest" opacity="0.70" rounded="sm">
      <b-row>
        <b-col md="6" xl="6">
          <b-card>
            <h3 class="text-center">Termo de Contrato</h3>
            <b-form-textarea
              id="value1"
              @change="newValueContract"
              :value="term_contract = terms.data.term_contract"
              rows="8"
            >
            {{terms.data.term_contract}}
            </b-form-textarea>
            
            <hr />
            <h3 class="text-center overflow-auto">Termo LGPD</h3>
            <b-form-textarea 
              id="value2"
              @change="newValueLgpd"
              :value="term_lgpd = terms.data.term_lgpd" 
              rows="8"
            >
            {{terms.data.term_lgpd}}
            </b-form-textarea>
            <b-row class="m-1">
              <b-button variant="gradient-primary" block @click="storeTerm">
                Cadastrar
              </b-button>
              <b-button variant="gradient-danger" block type="reset"> Limpar </b-button>
            </b-row>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="justify-content-md-center style-term overflow-auto">
           
              <h3 class="text-center">Termo de Contrato</h3>
              <hr />
              <p class="text-justify" v-if="terms == null">
                Nenhum termo cadastrado!
              </p>
              <p class="text-justify">{{ terms.data.term_contract }}</p>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="justify-content-md-center style-term overflow-auto">
              <h3 class="text-center">Termo LGPD</h3>
              <hr />
              <p class="text-justify" v-if="terms == null">
                Nenhum termo cadastrado!
              </p>
              <p class="text-justify">{{ terms.data.term_lgpd }}</p>
          </b-card>
        </b-col>
      </b-row>
      </b-overlay>
    </div>
    <div v-show="showCreate">
      <b-row v-if="terms"> 
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="UsersIcon"
            :statistic="termsStatus.user"
            color="primary"
            statistic-title="Total de Usuário"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="CheckIcon"
            :statistic="termsStatus.termAccept"
            color="success"
            statistic-title="Total de termos aceitos"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="FeatherIcon"
            :statistic="termsStatus.version"
            color="warning"
            statistic-title="Versão atual do termo"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card>
            <b-row class="ml-1">
              <div v-if="!showSearch" v-show="showCreate"> 
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  @click="showSearch = true"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </div>

              <div v-else v-show="showCreate">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-warning"
                  class="btn-icon rounded-circle"
                  @click="showSearch = false"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
              
              <b-col v-show="showSearch">
                <b-form-input
                  placeholder="Pesquisar"
                  v-model="search"
                  debounce="800"
                  size="sm"
                  type="search"
                />
              </b-col>
            </b-row>
            <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
              <b-table-simple
                striped
                stacked="md"
                class="mt-2 responsive text-center"
                id="tableAccept"
                :item="fetchTermAccept"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <b-thead>
                  <b-tr>
                    <b-th>Nome do Usuário</b-th>
                    <b-th>E-mail</b-th>
                    <b-th>Tipo</b-th>
                    <b-th>Data de confirmação</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr :key="indextr" v-for="(tr, indextr) in termsAccept">
                    <b-td>{{ tr.user.name }}</b-td>
                    <b-td>{{ tr.user.email }}</b-td>
                    <b-td>{{ tr.user.type }}</b-td>
                    <b-td>{{ tr.created_at | dateFormat }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <b-pagination
                @change="handlePageChange"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                aria-controls="tableAccept"
                class="mt-1"
              />
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    StatisticCardHorizontal
  },
  data() {
    return {
      showSearch: false,
      showCreate: false,
      isLoading: true,
      isLoadingLest: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      term_contract: null,
      term_lgpd: null,
      dateTerm: {
        term_contract: null,
        term_lgpd: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("terms", ["terms", "termsStatus", "termsAccept", "pagination"])
  },
  methods: {
    ...mapActions("terms", ["fetchLestTerm", "fetchTermStatus", "store"]),
    storeTerm() {
      this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      this.store({
        ...this.dateTerm,
      })
      .then(() => {
      })
      .finally(() =>{
        this.fetchLestTerm();
      });
    },
    fetchTermAccept(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("terms/fetchTermAccept", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    newValueContract(value){
      this.dateTerm.term_contract = value
    },
    newValueLgpd(value){
      this.dateTerm.term_lgpd = value
    },
    handlePageChange(value) {
      this.fetchTermAccept(true, value);
    },
  },
  created() {
    this.fetchLestTerm();
    this.fetchTermStatus();
    this.fetchTermAccept();
  },
  watch: {
    terms() {
      this.isLoadingLest = false;
    },
    search(val) {
      this.currentPage = 1;
      this.fetchTermAccept(true, 1);
    },
  },
};
</script>

<style lang="scss">
.style-term {
  width: 100%;
  height: 648px;
}
</style>
